<template>
  <div>
    <div class="staff">
      <div class="staff-title flex-cfs">
        <div class="title-text">基础信息</div>
      </div>
      <div class="info">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="140px"
          class="demo-ruleForm"
        >
          <el-form-item label="是否创建商户：">
            <el-radio v-model="ruleForm.radio" label="1">是</el-radio>
            <el-radio v-model="ruleForm.radio" label="0">否</el-radio>
          </el-form-item>
          <el-form-item label="选择商户：" v-if="ruleForm.radio == '0'" prop="merchantNo">
            <el-select v-model="ruleForm.merchantNo" placeholder="请选择">
              <el-option
                v-for="item in merchantList"
                :key="item.merchantNo"
                :label="item.merchantName"
                :value="item.merchantNo"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="商户名称："
            v-if="ruleForm.radio == '1'"
            prop="merchantName"
          >
            <el-input
              v-model="ruleForm.merchantName"
              placeholder="请输入商户名称"
            />
          </el-form-item>
          <el-form-item
            label="登录手机号："
            v-if="ruleForm.radio == '1'"
            prop="userName"
          >
            <el-input
              v-model="ruleForm.userName"
              placeholder="请输入登录手机号"
            />
          </el-form-item>
          <el-form-item
            label="版本"
            v-if="ruleForm.radio == '1'"
            prop="systemVersion"
          >
            <el-select
              v-model="ruleForm.systemVersion"
              placeholder="请选择版本"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="支付通道"
            v-if="ruleForm.radio == '1'"
            prop="platformType"
          >
            <el-select
              v-model="ruleForm.platformType"
              placeholder="请选择支付通道"
            >
              <el-option
                v-for="(item, index) in options1"
                :key="index"
                :label="item.name"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="入驻身份"
            v-if="ruleForm.radio == '1'"
            prop="merchantType"
          >
            <el-select
              v-model="ruleForm.merchantType"
              placeholder="请选择入驻身份"
            >
              <el-option
                v-for="(item, index) in options3"
                :key="index"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="经营品类"
            v-if="ruleForm.radio == '1'"
            prop="tradeTypeName"
          >
            <el-select
              v-model="ruleForm.tradeTypeName"
              placeholder="请选择经营品类"
              @change="onChange"
            >
              <el-option
                v-for="(item, index) in options2"
                :key="index"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="门店名称：" prop="shopName">
            <el-input
              v-model="ruleForm.shopName"
              placeholder="请输入门店名称"
            />
          </el-form-item>
          <el-form-item label="门店地址：" prop="shopAddr">
            <el-input
              v-model="ruleForm.shopAddr"
              placeholder="请输入门店地址"
            />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="staff" style="margin-bottom: 70px">
      <div class="staff-title flex-cfs">
        <div class="title-text">详细信息</div>
      </div>
      <div class="info">
        <el-form
          ref="ruleForm1"
          :model="ruleForm"
          :rules="rules"
          label-width="140px"
          class="demo-ruleForm"
        >
          <el-form-item label="联系人：">
            <el-input
              v-model="ruleForm.contactName"
              placeholder="请输入联系人"
            />
          </el-form-item>
          <el-form-item label="联系人电话：">
            <el-input
              v-model="ruleForm.phone"
              onkeyup="value=value.replace(/[^\d]/g,'')"
              placeholder="请输入联系人电话"
            />
          </el-form-item>
          <el-form-item label="门牌照：" prop="shopImgShow">
            <div class="flex-cfs">
              <el-image
                v-if="ruleForm.shopImgShow"
                style="width: 100px; height: 100px; margin-right: 10px"
                :src="ruleForm.shopImgShow"
                :preview-src-list="srcList"
              />
              <el-upload
                style="width: 100px; height: 100px"
                :action="action"
                list-type="picture-card"
                :on-success="handleSuccess"
                :show-file-list="false"
                accept="image/png, image/jpeg"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="店铺介绍：">
            <el-input v-model="ruleForm.shopDesc" type="textarea" autosize />
          </el-form-item>
          <el-form-item label="店铺公告：">
            <el-input v-model="ruleForm.notice" type="textarea" autosize />
          </el-form-item>
          <el-form-item label="营业时间：">
            <el-time-picker
              v-model="value"
              is-range
              arrow-control
              :clearable="false"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="buts flex-cc">
      <el-button type="primary" @click="$router.go(-1)">返 回</el-button>
      <el-button type="primary" @click="addshop">保 存</el-button>
    </div>
  </div>
</template>
<script>
import {
  queryMerchantListByMerCode,
  agentBaseSet,
  postSaveMerOffline,
  addshop,
} from "@/api/product/index";
export default {
  data() {
    return {
      ruleForm: {
        radio: "0",
        merchantType: "",
        closeTime: "",
        contactName: "",
        merchantNo: null,
        merchantName: "",
        notice: "",
        openTime: "",
        phone: "",
        shopAddr: "",
        shopDesc: "",
        shopImg: "",
        shopImgShow: "",
        shopName: "",
        userName: "",
        systemVersion: "",
        platformType: "",
        tradeTypeId: "",
        tradeTypeName: "",
        brandCode: JSON.parse(sessionStorage.getItem("LoginInfo")).merCode,
      },
      options: [
        { name: "新消费", id: 1 },
        { name: "专匠版", id: 2 },
        { name: "餐饮版", id: 3 },
      ],
      options1: [],
      options2: [],
      options3: [
        {
          text: "企业",
          value: "ENT",
        },
        {
          text: "小微",
          value: "MICRO",
        },
        {
          text: "个体户",
          value: "PER",
        },
      ],
      srcList: [],
      merchantList: [],
      rules: {
        shopName: [
          { required: true, message: "请输入店铺名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        tradeTypeName: [
          { required: true, message: "请输入经营类别", trigger: "blur" },
        ],
        shopAddr: [
          { required: true, message: "请输入门店地址", trigger: "blur" },
        ],
        merchantType: [
          { required: true, message: "请选择入驻身份", trigger: "blur" },
        ],
        tradeTypeName: [
          { required: true, message: "请选择经营品类", trigger: "blur" },
        ],
        platformType: [
          { required: true, message: "请选择支付通道", trigger: "blur" },
        ],
        systemVersion: [
          { required: true, message: "请选择版本", trigger: "blur" },
        ],
        merchantNo: [
          { required: true, message: "请选择商户", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "请输入登录手机号", trigger: "blur" },
        ],
        merchantName: [
          { required: true, message: "请输入商户名称", trigger: "blur" },
        ],
        contactName: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
          { min: 1, max: 5, message: "长度在 1 到 5 个字符", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确的电话", trigger: "blur" },
        ],
        shopImgShow: [
          { required: true, message: "请上传照片", trigger: "blur" },
        ],
      },
      action: this.$store.getters.fileServer,
      value: ["1970-02-01 00:00:00", "1970-02-01 11:59:00"],
    };
  },
  created() {
    let dict = this.$store.state.user.dict;
    dict.forEach((ele) => {
      if (ele.code == "dada_type") {
        this.options2 = ele.list;
      }
    });
    this.queryMerchantListByMerCode();
    this.agentBaseSet();
  },
  methods: {
    agentBaseSet() {
      let obj = {
        params: {
          isUse: 1,
        },
      };
      agentBaseSet(obj).then((res) => {
        if (res.data.flag) {
          res.data.data.forEach((ele) => {
            if (ele.accessChannel == 2) {
              this.options1.push(ele);
            }
          });
        }
      });
    },
    queryMerchantListByMerCode() {
      let data = {
        params: {
          merCode: JSON.parse(sessionStorage.getItem("LoginInfo")).merCode,
          merType: JSON.parse(sessionStorage.getItem("LoginInfo")).merType,
        },
      };
      queryMerchantListByMerCode(data).then((res) => {
        if (res.data.flag) {
          this.merchantList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    onChange(e) {
      this.options2.forEach((ele) => {
        if (ele.value == e) {
          this.ruleForm.tradeTypeId = ele.value;
          this.ruleForm.tradeTypeName = ele.text;
        }
      });
    },
    addshop() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$refs["ruleForm1"].validate((valid1) => {
            if (valid1) {
              let data = {
                params: this.ruleForm,
              };
              switch (this.ruleForm.radio) {
                case "0":
                  addshop(data).then((res) => {
                    if (res.data.flag) {
                      this.$message.success("新增成功");
                      this.$router.back(-1);
                    } else {
                      this.$message.error(res.data.msg);
                    }
                  });
                  break;
                case "1":
                  data.params.bizStoreName = data.params.shopName;
                  postSaveMerOffline(data).then((res) => {
                    if (res.data.flag) {
                      this.$message.success("新增成功");
                      this.$router.back(-1);
                    } else {
                      this.$message.error(res.data.msg);
                    }
                  });
                  break;
              }
            } else {
              return false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleSuccess(e) {
      if (e.flag) {
        this.ruleForm.shopImg = e.data.uri;
        this.ruleForm.shopImgShow = e.data.url;
        this.srcList = [e.data.url];
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>
<style scoped>
.flex-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buts {
  width: calc(100vw - 250px);
  background: #fff;
  height: 60px;
}
.staff {
  padding: 0 20px;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 20px;
}
.staff-title {
  padding: 18px 0;
  border-bottom: 1px solid #ebebeb;
}
.title-text {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin-right: 16px;
}
.info {
  padding: 18px 0 1px;
}
.el-input {
  width: 270px;
}
.el-select {
  width: 270px;
}
::v-deep .el-textarea__inner {
  width: 270px;
}
::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
</style>
